import { Expose } from 'class-transformer'
import DynamicFormData from '@/calendesk/models/DynamicFormData'

export default class CtaButtonData {
  public title: string

  @Expose({ name: 'page_id' })
  public pageId: string | null

  @Expose({ name: 'page_url' })
  public pageUrl?: string | null

  @Expose({ name: 'ssp_id' })
  public sspId: number | null

  @Expose({ name: 'service_id' })
  public serviceId: number | null

  @Expose({ name: 'service_type_id' })
  public serviceTypeId: number | null

  @Expose({ name: 'subscription_id' })
  public subscriptionId: number | null

  @Expose({ name: 'is_functional' })
  public isFunctional: boolean

  public outlined: boolean
  public rounded: boolean
  public size: string
  public width: number | null = null
  public elevation: number | null = null

  @Expose({ name: 'open_in_new_tab' })
  public openInNewTab?: boolean

  @Expose({ name: 'booking_form_data' })
  public bookingFormData: DynamicFormData[] | null

  @Expose({ name: 'show_billing_data' })
  public showBillingData: boolean

  @Expose({ name: 'require_billing_data' })
  public requireBillingData: boolean

  @Expose({ name: 'show_name_and_surname_fields' })
  public showNameAndSurnameFields: boolean

  @Expose({ name: 'show_phone_number_field' })
  public showPhoneNumberField: boolean

  @Expose({ name: 'phone_number_required' })
  public phoneNumberRequired: boolean

  @Expose({ name: 'select_initial_location' })
  public selectInitialLocation: boolean

  constructor (
    title: string,
    pageId: string | null,
    pageUrl: string | null,
    openInNewTab: boolean,
    outline: boolean,
    rounded: boolean,
    size: string,
    isFunctional: boolean,
    width: number | null = null,
    elevation: number | null = null,
    sspId: number | null = null,
    serviceId: number | null = null,
    serviceTypeId: number | null = null,
    subscriptionId: number | null = null,
    bookingFormData: DynamicFormData[] | null = null,
    showBillingData = true,
    requireBillingData = false,
    showNameAndSurnameFields = true,
    showPhoneNumberField = true,
    phoneNumberRequired = true,
    selectInitialLocation = true
  ) {
    this.title = title
    this.pageId = pageId
    this.pageUrl = pageUrl
    this.openInNewTab = openInNewTab
    this.outlined = outline
    this.rounded = rounded
    this.size = size
    this.isFunctional = isFunctional
    this.width = width
    this.elevation = elevation
    this.sspId = sspId
    this.serviceId = serviceId
    this.serviceTypeId = serviceTypeId
    this.subscriptionId = subscriptionId
    this.bookingFormData = bookingFormData
    this.showBillingData = showBillingData
    this.requireBillingData = requireBillingData
    this.showNameAndSurnameFields = showNameAndSurnameFields
    this.showPhoneNumberField = showPhoneNumberField
    this.phoneNumberRequired = phoneNumberRequired
    this.selectInitialLocation = selectInitialLocation
  }
}
